@import 'abstracts/variables';

// Shared styles between both carousel variants
.product-carousel {
  position: relative;

  .browse-link {
    position: absolute;
    top: 0;
    right: 0;
    color: @primary-darker;
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    width: 100%;
    gap: @spacer;
    margin-top: @spacer;

    @media @below-md {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  }
}

// Simple carousel specific styles
.simple {
  .product-carousel;

  .skeleton-loading-card-carousel {
    min-width: 160px; // Magic number to fix the card width to match the card design
    border-radius: @border-radius;

    &.skeleton-loading-card-grid {
      min-width: unset; // Let CSS grid handle the width
    }
  }
}

// Narrow carousel specific styles
.narrow {
  .product-carousel;

  .skeleton-loading-card-carousel {
    min-width: 190px; // Adjusted width for narrow card
    border-radius: @border-radius;

    &.skeleton-loading-card-grid {
      min-width: unset; // Let CSS grid handle the width
    }
  }
}
