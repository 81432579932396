@import '../abstracts/variables';
@import '../abstracts/helpers';
@import './CategoryCardLandingPage.module';

.see-all-card {
  .category-card;

  background: @dim-darker;
  height: 180px;
  min-width: unset;
  width: 190px;
  margin-right: @spacer * 1.5;

  .all-brands-cta {
    // No wrap to ensure arrow doesn't wrap to next line on FI
    flex-wrap: nowrap;

    h4 {
      text-wrap: nowrap;
      color: @primary;
    }
  }

  .description {
    color: #fff;
    margin-top: @spacer * 1.5;
  }

  :global {
    .rs-panel {
      padding: @spacer * 2.5;
      padding-top: @spacer * 6;
      padding-right: @spacer * 2;

      .rs-panel-body {
        display: flex;
        flex-direction: column;
        background: @dim-darker;
      }
    }
  }
}
