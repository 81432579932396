@import '../abstracts/variables';
@import '../abstracts/helpers';
@import './CategoryCardLandingPage.module';

.supplier-card {
  .category-card;

  height: 180px;
  min-width: 190px;
  text-align: center;
  color: @B800;
  margin-right: @spacer * 1.5;

  &:last-child {
    margin-right: 0;
  }

  :global {
    .rs-panel {
      box-shadow: none;
      padding: 0;
      padding-top: (@spacer/2);
    }
  }

  h5 {
    margin-top: @spacer;

    @media @below-md {
      margin-top: @spacer * 1.5;
    }
  }

  .delivery-text {
    .ellipsis;

    background: @B050;
    border-radius: 14px; // to match figma
    padding: (@spacer / 2) @spacer * 2;
    font-size: @font-size-base - 2;
    margin-top: @spacer;

    @media @below-md {
      padding: (@spacer / 2) @spacer;
    }
  }

  .supplier-logo {
    @avatar-dimension: 90px;

    box-shadow: 0 2px 10px 0 rgba(@B300, 0.5);
    border-radius: 50%;
    width: @avatar-dimension;
    margin: 0 auto;

    img {
      max-width: 100%;
      object-fit: contain;
      background-color: white;
    }

    @media @below-md {
      @avatar-dimension: 60px;

      width: @avatar-dimension;
      height: @avatar-dimension;

      & > div {
        width: @avatar-dimension;
        height: @avatar-dimension;
      }
    }
  }

  @media @below-md {
    :global {
      .rs-panel {
        padding: @spacer * 1.5 @spacer * 0.5;
      }
    }
  }
}
